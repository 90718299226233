// Libraries
import { css } from "styled-components"

// Utils
import { colors, weights } from "../../utils/variables"

export const Helpers = () => {
  let styles = ""

  Object.entries(weights).forEach(([name, weight]) => {
    styles += `
      // Font-weights classes
      .font-weight--${name} {
        font-weight: ${weight};
      }
    `
  })

  Object.entries(colors).forEach(([name, color]) => {
    styles += `
      // Text color classes
      .color--${name} {
        color: ${color};
      }

      // Hover color classes
      .color-hover--${name} {
        
        &:hover {
          color: ${color};
        }
      }

      // Background color classes
      .bg--${name} {
        background-color: ${color};
      }

      .bg-hover--${name} {
        
        &:hover {
          background-color: ${color};
        }
      }

      // Borders
      .border--${name} {
        border-color: ${color} !important;
      }

      .border-hover--${name} {
        
        &:hover {
          border-color: ${color} !important;
        }
      }

      // SVG Fills
      .svg--fill-${name} {
      
        *,
        svg * {
          fill: ${color};
        }
      }

      .svg-hover--fill-${name} {
      
        &:hover {
          *,
          svg * {
            fill: ${color};
          }
        }
      }

      // SVG Strokes
      .svg--stroke-${name} {

        *,
        svg * {
          stroke: ${color};
        }
      }

      .svg-hover--stroke-${name} {

        &:hover {
          *,
          svg * {
            stroke: ${color};
          }
        }
      }
    `
  })

  // Margins
  for (const x of Array(8).keys()) {
    styles += `
      .mt-${x} {
        margin-top: ${8 * x}px;
      }

      .mr-${x} {
        margin-right: ${8 * x}px;
      }

      .mb-${x} {
        margin-bottom: ${8 * x}px;
      }

      .ml-${x} {
        margin-left: ${8 * x}px;
      }
    `
  }

  return css`
    ${styles}
  `
}
