// Colors
export const colors = {
  dark: '#0D022A',
  violeta_medifar: '#200762',
  celeste: '#9BC4F3',
  celeste_bebe: '#E4E8ED',
  cream: '#FBF7F4',
  text_white: '#FFFFFF',
  gray_disabled: '#949494',
  text_disabled: '#6D6D6D',
  fuscia_medifar: '#DE4789',
  rosa_medifar: '#F8AC9C',
};

// Fonts
export const fonts = {
  primary: 'Poppins',
  secondary: 'Poppins',
  userTest: 'Poppins',
};

// Font Weights
export const weights = {
  400: '400',
  500: '500',
  600: '600',
  700: '700',
};

// Breakpoints
export const breakpoints = {
  small: '768',
  medium: '1024',
  large: '1200',
  extraLarge: '1440',
  wide: '2000',
};
