import { createGlobalStyle } from 'styled-components';

// Bootstrap
import './bootstrap.scss';

// Utils
import breakpoint from '../../utils/breakpoints';
import { colors } from '../../utils/variables';

// Fonts
import Poppins400 from '../fonts/Poppins-Regular.ttf';
import Poppins500 from '../fonts/Poppins-Medium.ttf';
import Poppins600 from '../fonts/Poppins-SemiBold.ttf';
import Poppins700 from '../fonts/Poppins-Bold.ttf';

// Helpers
import { Helpers } from './helpers';

const GlobalStyles = createGlobalStyle`

@font-face {
  font-family: 'Poppins';
  src: url(${Poppins400}) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url(${Poppins500}) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url(${Poppins600}) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url(${Poppins700}) format('truetype');
  font-weight: 700;
  font-style: normal;
}

html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Evita el scroll horizontal */
  width: 100%;
  scroll-behavior: smooth;
}

  body {
    font-family: "Poppins", sans-serif !important;
    color: ${colors.dark};
    background-color: ${colors.cream};
    font-size: 20px;
    line-height: 1.4em;
    overflow-x: hidden;
    overflow-y: hidden;

  
      margin: 0;
      padding: 0;
      overflow-x: hidden; /* Evita el scroll horizontal */
      width: 100%;
    
    
    a {
      font-size: 16px;
      line-height: 19.2px;
      color: inherit;
      text-decoration: none;

      &.size--small {
        font-size: 18px;
        line-height: 26px;
      }
    }

    p {
      font-size: 16px;
      line-height: 19.2px;
    }
    
    h1,
    h2,
    h3,
    h4 {
      margin: 0;
      font-weight: 400;
      line-height: 1.2em;


    h1 {
      font-size: 36px;
      line-height: 43.2px;

      ${breakpoint.medium`
        font-size: 64px;
        line-height: 76.8px;

      `}
    }

    h2 {
      font-size: 34px;
      line-height: 38.4px;


      ${breakpoint.medium`
        font-size: 48px;
        line-height: 56px;

      `}
    }
    
    h3 {
      font-size: 24px;

      ${breakpoint.medium`
        font-size: 40px;
      `}
    }

    h4 {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: .02em;
      line-height: 22.4px;

    }
  }

  ${Helpers}

`;

export default GlobalStyles;
