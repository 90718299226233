exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-construction-js": () => import("./../../../src/pages/construction.js" /* webpackChunkName: "component---src-pages-construction-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-exeol-js": () => import("./../../../src/pages/exeol.js" /* webpackChunkName: "component---src-pages-exeol-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lentismed-js": () => import("./../../../src/pages/lentismed.js" /* webpackChunkName: "component---src-pages-lentismed-js" */),
  "component---src-pages-representations-js": () => import("./../../../src/pages/representations.js" /* webpackChunkName: "component---src-pages-representations-js" */),
  "component---src-pages-solventum-js": () => import("./../../../src/pages/solventum.js" /* webpackChunkName: "component---src-pages-solventum-js" */)
}

